import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { GendersService } from '../../services/genders.service';
import { Gender } from '../../models/Gender';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';

export interface DialogData {
    modalTitle: string;
    modalButtonText: string;
    bDisable: boolean;
    cName: string;
}

@Component({
    selector: 'app-genders',
    templateUrl: './genders.component.html',
    styleUrls: ['./genders.component.css']
})
export class GendersComponent implements OnInit {

    isLoading: boolean = false;
    panelOpenState = false;
    modalTitle: string;
    modalButtonText: string;
    bDisable: boolean;
    cName: string;
    genders: Gender[] = [];
    constructor(public gendersService: GendersService, public dialog: MatDialog, private toastr: ToastrService) { }
    displayedColumns: string[] = ['idGender', 'cName', 'actions'];
    dataSource = new MatTableDataSource<Gender>(this.genders);

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;


    ngOnInit() {
        this.isLoading = true;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.gendersService.getGenders().subscribe(
            genders => {
                this.dataSource.data = genders;
                this.isLoading = false;
            },
            err => console.log(err)
        )
    }

    refresh() {
        this.isLoading = true;
        this.gendersService.getGenders().subscribe(
            genders => {
                this.dataSource.data = genders;
                this.isLoading = false;
            },
            err => console.log(err)
        )
    }

    openAddDialog() {
        this.modalTitle = "Agregar";
        this.modalButtonText = "Guardar";
        this.bDisable = false;
        const dialogRef = this.dialog.open(GenderModal, {
            width: '50em',
            data: { cName: this.cName, modalTitle: this.modalTitle, modalButtonText: this.modalButtonText, bDisable: this.bDisable }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.isLoading = true;
                var data = { cName: result.cName };
                this.gendersService.saveGender(data).subscribe((res) => {
                    this.toastr.success('Éxito', 'Genero registrado');
                    this.isLoading = false;
                    this.refresh();
                }, err => {
                    this.isLoading = false;
                    this.toastr.error('Error', 'Occurrio un error al insertar el genero');
                });
            }
        });
    }


    openEditDialog(element) {
        this.modalTitle = "Editar";
        this.modalButtonText = "Guardar";
        this.bDisable = false;
        const dialogRef = this.dialog.open(GenderModal, {
            width: '50em',
            data: { cName: element.cName, modalTitle: this.modalTitle, modalButtonText: this.modalButtonText, bDisable: this.bDisable }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.isLoading = true;
                var data = {
                    idGender: element.idGender,
                    cName: result.cName
                }
                this.gendersService.editGender(data).subscribe((res) => {
                    this.toastr.success('Éxito', 'Genero actualizado');
                    this.isLoading = false;
                    this.refresh();
                }, err => {
                    this.isLoading = false;
                    this.toastr.error('Error', 'Occurrio un error al actualizar el genero');
                });
            }
        });
    }

    openDeleteDialog(element) {
        this.modalTitle = "Eliminar";
        this.modalButtonText = "Eliminar";
        this.bDisable = true;
        const dialogRef = this.dialog.open(GenderModal, {
            width: '50em',
            data: { cName: element.cName, modalTitle: this.modalTitle, modalButtonText: this.modalButtonText, bDisable: this.bDisable }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.isLoading = true;
                var data = {
                    idGender: element.idGender
                }
                this.deleteElement(data);
            }
        });
    }

    deleteElement(data){
        console.log(data);
        this.gendersService.deleteGender(data).subscribe((res) => {
            this.toastr.success('Éxito', 'Genero eliminado');
            this.isLoading = false;
            this.refresh();
        }, err => {
            this.isLoading = false;
            this.toastr.error('Error', 'Occurrio un error al eliminar el genero');
        });
    }

    loadEdit(element) {
        this.openEditDialog(element)
    }

    loadDelete(element) {
        this.openDeleteDialog(element)
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase()
    }
}

@Component({
    selector: 'genderModal',
    templateUrl: 'genderModal.html',
})
export class GenderModal {
    constructor(public dialogRef: MatDialogRef<GenderModal>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

    onNoClick(): void {
        this.dialogRef.close();
    }
}