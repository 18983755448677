import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Gender } from '../models/Gender';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class GendersService {

    constructor(private http: HttpClient) { }

    getGenders() {
        return this.http.get<Gender[]>(environment.baseUrl + "genders");
    }

    saveGender(gender) {
        return this.http.post(environment.baseUrl + "genders", gender);
    }

    editGender(gender) {
        return this.http.put(environment.baseUrl + "genders", gender);
    }

    deleteGender(gender) {
        return this.http.patch(environment.baseUrl + "genders", gender);
    }
}