import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Publisher } from '../models/Publisher';
import { environment } from 'src/environments/environment.prod';

@Injectable({
    providedIn: 'root'
})
export class PublishersService {

    constructor(private http: HttpClient) { }

    getPublishers() {
        return this.http.get<Publisher[]>(environment.baseUrl + "publishers");
    }

    savePublisher(publisher) {
        return this.http.post(environment.baseUrl + "publishers", publisher);
    }

    editPublisher(publisher) {
        return this.http.put(environment.baseUrl + "publishers", publisher);
    }

    deletePublisher(publisher) {
        return this.http.patch(environment.baseUrl + "publishers", publisher);
    }
}