import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Author } from '../models/Author';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthorsService {

    constructor(private http: HttpClient) { }

    getAuthors() {
        return this.http.get<Author[]>(environment.baseUrl + "authors");
    }

    saveAuthor(author) {
        return this.http.post(environment.baseUrl + "authors", author);
    }

    editAuthor(author) {
        return this.http.put(environment.baseUrl + "authors", author);
    }

    deleteAuthor(author) {
        return this.http.patch(environment.baseUrl + "authors", author);
    }

}