import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Sale } from '../models/Sale';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class SalesService {

  constructor(private http: HttpClient) { }

  getSales() {
    return this.http.get<Sale[]>(environment.baseUrl + "sales");
  }

  saveSale(sale) {
    return this.http.post(environment.baseUrl + "sale", sale);
  }

  outOfInventory(sale) {
    return this.http.post(environment.baseUrl + "outofinventory", sale);
  }
  
  // editSale(sale) {
  //     return this.http.put(environment.baseUrl + "sales", sale);
  // }

  // deleteSale(sale) {
  //     return this.http.patch(environment.baseUrl + "sales", sale);
  // }
}

