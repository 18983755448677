import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { EntriesService } from '../../services/entries.service';
import { Entry } from '../../models/Entry';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';

export interface DialogData {
    modalTitle: string;
    modalButtonText: string;
    bDisable: boolean;
    cName: string;
}

@Component({
  selector: 'app-entries',
  templateUrl: './entries.component.html',
  styleUrls: ['./entries.component.css']
})
export class EntriesComponent implements OnInit {

    isLoading: boolean = false;
    panelOpenState = false;
    modalTitle: string;
    modalButtonText: string;
    bDisable: boolean;
    cName: string;
    entries: Entry[] = [];
    constructor(public entriesService: EntriesService, public dialog: MatDialog, private toastr: ToastrService) { }
    displayedColumns: string[] = ['idEntry', 'cName', 'nQuantity', 'dDateEntry'];
    dataSource = new MatTableDataSource<Entry>(this.entries);

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;


    ngOnInit() {
        this.isLoading = true;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.entriesService.getEntries().subscribe(
            entries => {
                this.dataSource.data = entries;
                this.isLoading = false;
            },
            err => console.log(err)
        )
    }

    refresh() {
        this.isLoading = true;
        this.entriesService.getEntries().subscribe(
            entries => {
                this.dataSource.data = entries;
                this.isLoading = false;
            },
            err => console.log(err)
        )
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase()
    }
}
