import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { SalesService } from '../../services/sales.service';
import { Sale } from '../../models/Sale';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';

export interface DialogData {
    modalTitle: string;
    modalButtonText: string;
    bDisable: boolean;
    cName: string;
}

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.css']
})
export class SalesComponent implements OnInit {

    isLoading: boolean = false;
    panelOpenState = false;
    modalTitle: string;
    modalButtonText: string;
    bDisable: boolean;
    cName: string;
    sales: Sale[] = [];
    constructor(public salesService: SalesService, public dialog: MatDialog, private toastr: ToastrService) { }
    displayedColumns: string[] = ['idSale', 'cName', 'nQuantity', 'dDateSale'];
    dataSource = new MatTableDataSource<Sale>(this.sales);

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;


    ngOnInit() {
        this.isLoading = true;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.salesService.getSales().subscribe(
            sales => {
                this.dataSource.data = sales;
                this.isLoading = false;
            },
            err => console.log(err)
        )
    }

    refresh() {
        this.isLoading = true;
        this.salesService.getSales().subscribe(
            sales => {
                this.dataSource.data = sales;
                this.isLoading = false;
            },
            err => console.log(err)
        )
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase()
    }
}
