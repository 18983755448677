import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { BooksService } from '../../services/books.service';
import { Book } from '../../models/Book';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl } from '@angular/forms'
import { Observable } from 'rxjs';
import { AuthorsService } from 'src/app/services/authors.service';
import { Author } from 'src/app/models/Author';
import 'rxjs/Rx';
import 'rxjs/add/observable/of';
import { Gender } from 'src/app/models/Gender';
import { Publisher } from 'src/app/models/Publisher';
import { PublishersService } from 'src/app/services/publishers.service';
import { GendersService } from 'src/app/services/genders.service';
import { ToastrService } from 'ngx-toastr';

export interface DialogData {
    modalTitle: string;
    modalButtonText: string;
    bVisible: boolean;
    bDisable: boolean;
    cName: string;
    cSku: string;
    nCost: number;
    nPrice: number;
    nQuantity: number;
    idAuthor: number;
    idGender: number;
    idPublisher: number;
}

@Component({
    selector: 'app-books',
    templateUrl: './books.component.html',
    styleUrls: ['./books.component.css']
})
export class BooksComponent implements OnInit {

    isLoading: boolean = false;
    panelOpenState = false;
    modalTitle: string;
    modalButtonText: string;
    bVisible: boolean;
    bDisable: boolean;
    cName: string;
    cSku: string;
    nCost: number;
    nPrice: number;
    nQuantity: number;
    idAuthor: {
        idAuthor: number, cName: string, nStatus: number
    };
    idGender: {
        idGender: number, cName: string, nStatus: number
    };
    idPublisher: {
        idPublisher: number, cName: string, nStatus: number
    };
    books: Book[] = [];
    constructor(public booksService: BooksService, public dialog: MatDialog, private toastr: ToastrService) { }
    displayedColumns: string[] = ['idBook', 'cName', 'nPrice', 'nQuantity', 'cAuthor', 'cGender', 'cPublisher', 'actions'];
    dataSource = new MatTableDataSource<Book>(this.books);

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    ngOnInit() {
        this.isLoading = true;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.booksService.getBooks().subscribe(
            books => {
                this.dataSource.data = books;
                this.isLoading = false;
            },
            err => console.log(err)
        )
    }

    refresh() {
        this.isLoading = true;
        this.booksService.getBooks().subscribe(
            books => {
                this.dataSource.data = books;
                this.isLoading = false;
            },
            err => console.log(err)
        )
    }

    openAddDialog() {
        this.modalTitle = "Agregar";
        this.modalButtonText = "Guardar";
        this.bVisible = true;
        this.bDisable = false;
        const dialogRef = this.dialog.open(BookModal, {
            width: '50em',
            data: { cName: this.cName, cSku: this.cSku, nCost: this.nCost, nPrice: this.nPrice, idAuthor: this.idAuthor, modalTitle: this.modalTitle, modalButtonText: this.modalButtonText, bDisable:this.bDisable, bVisible: this.bVisible }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.isLoading = true;
                var data = {
                    cName: result.cName,
                    cSku: result.cSku,
                    nCost: result.nCost,
                    nPrice: result.nPrice,
                    nQuantity: result.nQuantity,
                    idAuthor: result.idAuthor.idAuthor,
                    idGender: result.idGender.idGender,
                    idPublisher: result.idPublisher.idPublisher
                };
                //console.log(data);
                this.booksService.saveBook(data).subscribe((res) => {
                    this.toastr.success('Éxito', 'Libro registrado');
                    this.isLoading = false;
                    this.refresh();
                }, err => {
                    this.isLoading = false;
                    this.toastr.error('Error', 'Occurrio un error al insertar el libro');
                });
            }
        });
    }


    openEditDialog(element) {
        this.modalTitle = "Editar";
        this.modalButtonText = "Guardar";
        this.bVisible = true;
        this.bDisable = false;

        //console.log(element)
        const dialogRef = this.dialog.open(BookModal, {
            width: '50em',
            data: {
                cName: element.cName,
                cSku: element.cSku,
                nCost: element.nCost,
                nPrice: element.nPrice,
                nQuantity: element.nQuantity,
                idAuthor: element.idAuthor,
                idGender: element.idGender,
                idPublisher: element.idPublisher,
                modalTitle: this.modalTitle, 
                modalButtonText: this.modalButtonText, 
                bDisable: this.bDisable, 
                bVisible: this.bVisible
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.isLoading = true;
                var data = {
                    idBook: element.idBook,
                    cName: result.cName,
                    cSku: result.cSku,
                    nCost: result.nCost,
                    nPrice: result.nPrice,
                    nQuantity: result.nQuantity,
                    idAuthor: result.idAuthor.idAuthor,
                    idGender: result.idGender.idGender,
                    idPublisher: result.idPublisher.idPublisher
                }
                this.booksService.editBook(data).subscribe((res) => {
                    this.toastr.success('Éxito', 'Libro actualizado');
                    this.isLoading = false;
                    this.refresh();
                }, err => {
                    this.isLoading = false;
                    this.toastr.error('Error', 'Occurrio un error al actualizar el libro');
                });
            }
        });
    }

    openDeleteDialog(element) {
        this.modalTitle = "Eliminar";
        this.modalButtonText = "Eliminar";
        this.bVisible = false;
        this.bDisable = true;
        const dialogRef = this.dialog.open(BookModal, {
            width: '50em',
            data: { cName: element.cName, modalTitle: this.modalTitle, modalButtonText: this.modalButtonText, bDisable: this.bDisable, bVisible: this.bVisible  }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.isLoading = true;
                var data = {
                    idBook: element.idBook
                }
                this.deleteElement(data);
            }
        });
    }

    deleteElement(data) {
        //console.log(data);
        this.booksService.deleteBook(data).subscribe((res) => {
            this.toastr.success('Éxito', 'Libro eliminado');
            this.isLoading = false;
            this.refresh();
        }, err => {
            this.isLoading = false;
            this.toastr.error('Error', 'Occurrio un error al eliminar el libro');
        });
    }

    loadEdit(element) {
        this.openEditDialog(element)
    }

    loadDelete(element) {
        this.openDeleteDialog(element)
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase()
    }
}

@Component({
    selector: 'bookModal',
    templateUrl: 'bookModal.html',
})
export class BookModal {

    authorControl = new FormControl();
    filteredAuthors: Observable<Author[]>;
    authors: Observable<Author[]>

    genderControl = new FormControl();
    filteredGenders: Observable<Gender[]>;
    genders: Observable<Gender[]>

    publisherControl = new FormControl();
    filteredPublishers: Observable<Publisher[]>;
    publishers: Observable<Publisher[]>

    bVisible :boolean;
    bDisable :boolean;

    constructor(public authorsService: AuthorsService, public gendersService: GendersService, public publishersService: PublishersService, public dialogRef: MatDialogRef<BookModal>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {

        this.authors = this.authorsService.getAuthors();
        this.genders = this.gendersService.getGenders();
        this.publishers = this.publishersService.getPublishers()
        this.bVisible = data.bVisible;
        this.bDisable = data.bDisable;

        this.filteredAuthors = this.authorControl.valueChanges
            .startWith(null)
            .debounceTime(10)
            .distinctUntilChanged()
            .switchMap(val1 => {
                if (val1 != null) 
                    this.data.idAuthor = val1;
                return this.filterAuthors(val1 || '')
            })

        this.filteredGenders = this.genderControl.valueChanges
            .startWith(null)
            .debounceTime(10)
            .distinctUntilChanged()
            .switchMap(val2 => {
                if (val2 != null) 
                    this.data.idGender = val2;
                return this.filterGenders(val2 || '')
            })

        this.filteredPublishers = this.publisherControl.valueChanges
            .startWith(null)
            .debounceTime(200)
            .distinctUntilChanged()
            .switchMap(val3 => {
                if (val3 != null) 
                    this.data.idPublisher = val3;
                return this.filterPublishers(val3 || '')
            })


        if (data.idAuthor) {
            this.authors.subscribe(res => {
                for (var i = 0; i < res.length; i++) {
                    if (res[i].idAuthor == data.idAuthor) {
                        this.authorControl.setValue(res[i]);
                        return;
                    }
                }
            })
        }

        if (data.idGender) {
            this.genders.subscribe(res => {
                for (var i = 0; i < res.length; i++) {
                    if (res[i].idGender == data.idGender) {
                        this.genderControl.setValue(res[i]);
                        return;
                    }
                }
            })
        }

        if (data.idPublisher) {
            this.publishers.subscribe(res => {
                for (var i = 0; i < res.length; i++) {
                    if (res[i].idPublisher == data.idPublisher) {
                        this.publisherControl.setValue(res[i]);
                        return;
                    }
                }
            })
        }
    }

    filterAuthors(val1: string) {
        return this.authors
            .map(response1 => response1.filter(option1 => {
                return option1.cName.toLowerCase().includes(val1)
            }));
    }

    filterGenders(val2: string) {
        return this.genders
            .map(response2 => response2.filter(option2 => {
                return option2.cName.toLowerCase().includes(val2)
            }));
    }

    filterPublishers(val3: string) {
        return this.publishers
            .map(response3 => response3.filter(option3 => {
                return option3.cName.toLowerCase().includes(val3)
            }));
    }

    displayAuthor(author) {
        return author ? author.cName : undefined;
    }

    displayGender(gender) {
        return gender ? gender.cName : undefined;
    }

    displayPublisher(publisher) {
        return publisher ? publisher.cName : undefined;
    }

}







