import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthorsComponent } from './components/authors/authors.component';
import { BillsComponent } from './components/bills/bills.component';
import { BooksComponent } from './components/books/books.component';
import { ClientsComponent } from './components/clients/clients.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { EntriesComponent } from './components/entries/entries.component';
import { GendersComponent } from './components/genders/genders.component';
import { PosComponent } from './components/pos/pos.component';
import { PublishersComponent } from './components/publishers/publishers.component';
import { SalesComponent } from './components/sales/sales.component';
import { InventorysalesComponent } from './components/inventorysales/inventorysales.component';

const routes: Routes = [
    { path: 'authors', component: AuthorsComponent },
    { path: 'bills', component: BillsComponent },
    { path: 'books', component: BooksComponent },
    { path: 'clients', component: ClientsComponent },
    { path: 'dashboard', component: DashboardComponent },
    { path: 'entries', component: EntriesComponent },
    { path: 'genders', component: GendersComponent },
    { path: 'pos', component: PosComponent },
    { path: 'publishers', component: PublishersComponent },
    { path: 'sales', component: SalesComponent },
    { path: 'isales', component: InventorysalesComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
