import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { MaterialModule } from './material.module';
import { BooksComponent, BookModal } from './components/books/books.component';
import { ClientsComponent } from './components/clients/clients.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { PosComponent } from './components/pos/pos.component';
import { BillsComponent } from './components/bills/bills.component';
import { EntriesComponent } from './components/entries/entries.component';
import { SalesComponent } from './components/sales/sales.component';
import { GendersComponent, GenderModal } from './components/genders/genders.component';
import { AuthorsComponent, AuthorModal } from './components/authors/authors.component';
import { PublishersComponent, PublisherModal } from './components/publishers/publishers.component';

import { NgxCurrencyModule } from "ngx-currency";
import { ToastrModule } from 'ngx-toastr'; 
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

import {NgxPrintModule} from 'ngx-print';
import { InventorysalesComponent } from './components/inventorysales/inventorysales.component';

@NgModule({
  declarations: [
    AppComponent,
    BooksComponent,
    ClientsComponent,
    SidenavComponent,
    DashboardComponent,
    PosComponent,
    BillsComponent,
    EntriesComponent,
    SalesComponent,
    GendersComponent,
    AuthorsComponent,
    PublishersComponent,
    PublisherModal,
    AuthorModal,
    GenderModal,
    BookModal,
    InventorysalesComponent
  ],
  entryComponents: [
    PublisherModal,
    AuthorModal,
    GenderModal,
    BookModal
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    NgxCurrencyModule,
    NgxPrintModule
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule { }
