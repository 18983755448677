import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { PublishersService } from '../../services/publishers.service';
import { Publisher } from '../../models/Publisher';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';

export interface DialogData {
    modalTitle: string;
    modalButtonText: string;
    bDisable: boolean;
    cName: string;
}

@Component({
    selector: 'app-publishers',
    templateUrl: './publishers.component.html',
    styleUrls: ['./publishers.component.css']
})
export class PublishersComponent implements OnInit {

    isLoading: boolean = false;
    panelOpenState = false;
    modalTitle: string;
    modalButtonText: string;
    bDisable: boolean;
    cName: string;
    publishers: Publisher[] = [];
    constructor(public publishersService: PublishersService, public dialog: MatDialog, private toastr: ToastrService) { }
    displayedColumns: string[] = ['idPublisher', 'cName', 'actions'];
    dataSource = new MatTableDataSource<Publisher>(this.publishers);

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    ngOnInit() {
        this.isLoading = true;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.publishersService.getPublishers().subscribe(
            publishers => {
                this.dataSource.data = publishers;
                this.isLoading = false;
            },
            err => console.log(err)
        )
    }

    refresh() {
        this.isLoading = true;
        this.publishersService.getPublishers().subscribe(
            publishers => {
                this.dataSource.data = publishers;
                this.isLoading = false;
            },
            err => console.log(err)
        )
    }

    openAddDialog() {
        this.modalTitle = "Agregar";
        this.modalButtonText = "Guardar";
        this.bDisable = false;
        const dialogRef = this.dialog.open(PublisherModal, {
            width: '50em',
            data: { cName: this.cName, modalTitle: this.modalTitle, modalButtonText: this.modalButtonText, bDisable: this.bDisable }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.isLoading = true;
                var data = { cName: result.cName };
                this.publishersService.savePublisher(data).subscribe((res) => {
                    this.toastr.success('Éxito', 'Editorial registrada');
                    this.isLoading = false;
                    this.refresh();
                }, err => {
                    this.isLoading = false;
                    this.toastr.error('Error', 'Occurrio un error al insertar la editorial');
                });
            }
        });
    }


    openEditDialog(element) {
        this.modalTitle = "Editar";
        this.modalButtonText = "Guardar";
        this.bDisable = false;
        const dialogRef = this.dialog.open(PublisherModal, {
            width: '50em',
            data: { cName: element.cName, modalTitle: this.modalTitle, modalButtonText: this.modalButtonText, bDisable: this.bDisable }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.isLoading = true;
                var data = {
                    idPublisher: element.idPublisher,
                    cName: result.cName
                }
                this.publishersService.editPublisher(data).subscribe((res) => {
                    this.toastr.success('Éxito', 'Editorial actualizada');
                    this.isLoading = false;
                    this.refresh();
                }, err => {
                    this.isLoading = false;
                    this.toastr.error('Error', 'Occurrio un error al actualizar la editorial');
                });
            }
        });
    }

    openDeleteDialog(element) {
        this.modalTitle = "Eliminar";
        this.modalButtonText = "Eliminar";
        this.bDisable = true;
        const dialogRef = this.dialog.open(PublisherModal, {
            width: '50em',
            data: { cName: element.cName, modalTitle: this.modalTitle, modalButtonText: this.modalButtonText, bDisable: this.bDisable }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.isLoading = true;
                var data = {
                    idPublisher: element.idPublisher
                }
                this.deleteElement(data);
            }
        });
    }

    deleteElement(data){
        console.log(data);
        this.publishersService.deletePublisher(data).subscribe((res) => {
            this.toastr.success('Éxito', 'Editorial eliminada');
            this.isLoading = false;
            this.refresh();
        }, err => {
            this.isLoading = false;
            this.toastr.error('Error', 'Occurrio un error al actualizar la editorial');
        });
    }

    loadEdit(element) {
        this.openEditDialog(element)
    }

    loadDelete(element) {
        this.openDeleteDialog(element)
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase()
    }
}

@Component({
    selector: 'publisherModal',
    templateUrl: 'publisherModal.html',
})
export class PublisherModal {
    constructor(public dialogRef: MatDialogRef<PublisherModal>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

    onNoClick(): void {
        this.dialogRef.close();
    }
}