import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { AuthorsService } from '../../services/authors.service';
import { Author } from '../../models/Author';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';

export interface DialogData {
    modalTitle: string;
    modalButtonText: string;
    bDisable: boolean;
    cName: string;
}

@Component({
    selector: 'app-authors',
    templateUrl: './authors.component.html',
    styleUrls: ['./authors.component.css']
})
export class AuthorsComponent implements OnInit {

    isLoading: boolean = false;
    panelOpenState = false;
    modalTitle: string;
    modalButtonText: string;
    bDisable: boolean;
    cName: string;
    authors: Author[] = [];
    constructor(public authorsService: AuthorsService, public dialog: MatDialog, private toastr: ToastrService) { }
    displayedColumns: string[] = ['idAuthor', 'cName', 'actions'];
    dataSource = new MatTableDataSource<Author>(this.authors);

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    ngOnInit() {
        this.isLoading = true;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.authorsService.getAuthors().subscribe(
            authors => {
                this.dataSource.data = authors;
                this.isLoading = false;
            },
            err => console.log(err)
        )
    }

    refresh() {
        this.isLoading = true;
        this.authorsService.getAuthors().subscribe(
            authors => {
                this.dataSource.data = authors;
                this.isLoading = false;
            },
            err => console.log(err)
        )
    }

    openAddDialog() {
        this.modalTitle = "Agregar";
        this.modalButtonText = "Guardar";
        this.bDisable = false;
        const dialogRef = this.dialog.open(AuthorModal, {
            width: '50em',
            data: { cName: this.cName, modalTitle: this.modalTitle, modalButtonText: this.modalButtonText, bDisable: this.bDisable }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.isLoading = true;
                var data = { cName: result.cName };
                this.authorsService.saveAuthor(data).subscribe((res) => {
                    this.toastr.success('Éxito', 'Autor registrado');
                    this.isLoading = false;
                    this.refresh();
                }, err => {
                    this.isLoading = false;
                    this.toastr.error('Error', 'Occurrio un error al insertar el autor');
                });
            }
        });
    }
    openEditDialog(element) {
        this.modalTitle = "Editar";
        this.modalButtonText = "Guardar";
        this.bDisable = false;
        const dialogRef = this.dialog.open(AuthorModal, {
            width: '50em',
            data: { cName: element.cName, modalTitle: this.modalTitle, modalButtonText: this.modalButtonText, bDisable: this.bDisable }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.isLoading = true;
                var data = {
                    idAuthor: element.idAuthor,
                    cName: result.cName
                }
                this.authorsService.editAuthor(data).subscribe((res) => {
                    this.toastr.success('Éxito', 'Autor actualizado');
                    this.isLoading = false;
                    this.refresh();
                }, err => {
                    this.isLoading = false;
                    this.toastr.error('Error', 'Occurrio un error al actualizar el autor');
                });
            }
        });
    }

    openDeleteDialog(element) {
        this.modalTitle = "Eliminar";
        this.modalButtonText = "Eliminar";
        this.bDisable = true;
        const dialogRef = this.dialog.open(AuthorModal, {
            width: '50em',
            data: { cName: element.cName, modalTitle: this.modalTitle, modalButtonText: this.modalButtonText, bDisable: this.bDisable }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.isLoading = true;
                var data = {
                    idAuthor: element.idAuthor
                }
                this.deleteElement(data);
            }
        });
    }

    deleteElement(data) {
        console.log(data);
        this.authorsService.deleteAuthor(data).subscribe((res) => {
            this.toastr.success('Éxito', 'Autor eliminado');
            this.isLoading = false;
            this.refresh();
        }, err => {
            this.isLoading = false;
            this.toastr.error('Error', 'Occurrio un error al eliminar el autor');
        });
    }

    loadEdit(element) {
        this.openEditDialog(element)
    }

    loadDelete(element) {
        this.openDeleteDialog(element)
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase()
    }
}

@Component({
    selector: 'authorModal',
    templateUrl: 'authorModal.html',
})
export class AuthorModal {
    constructor(public dialogRef: MatDialogRef<AuthorModal>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

    onNoClick(): void {
        this.dialogRef.close();
    }
}