import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Book } from '../models/Book';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class BooksService {

    constructor(private http: HttpClient) { }

    getBooks() {
        return this.http.get<Book[]>(environment.baseUrl + "books");
    }

    saveBook(book) {
        return this.http.post(environment.baseUrl + "books", book);
    }

    editBook(book) {
        return this.http.put(environment.baseUrl + "books", book);
    }

    deleteBook(book) {
        return this.http.patch(environment.baseUrl + "books", book);
    }
}
