import { Component, OnInit } from '@angular/core';
import { BooksService } from 'src/app/services/books.service';
import { Book } from 'src/app/models/Book';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, switchMap, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material';
import { SaleBook } from 'src/app/models/SaleBook';
import { ToastrService } from 'ngx-toastr';
import { SalesService } from 'src/app/services/sales.service';
var moment = require('moment');

@Component({
    selector: 'app-pos',
    templateUrl: './pos.component.html',
    styleUrls: ['./pos.component.css']
})
export class PosComponent implements OnInit {

    myDate: string;

    bookControl = new FormControl();
    books: Observable<Book[]>;
    filteredBooks: Observable<Book[]>;
    nDiscount = 0;
    nPrice = 0;
    nSubTotal = 0;
    nQuantity = 1;
    oBook: Book;
    nTotalBooks = 0;
    nTotal = 0;
    bEnableAddButton = false;

    salesBooks: SaleBook[] = [];
    displayedColumns: string[] = ['nQuantity', 'cSku', 'cName', 'nDiscount', 'nPrice', 'nSubtotal', 'actions'];
    dataSource = new MatTableDataSource<SaleBook>(this.salesBooks);

    constructor(
        public booksService: BooksService,
        private toastr: ToastrService,
        public salesService: SalesService) {

        this.myDate = moment().format("DD/MM/YYYY");

        this.books = this.booksService.getBooks();

        this.filteredBooks = this.bookControl.valueChanges.pipe(
            startWith(''),
            debounceTime(200),
            distinctUntilChanged(),
            switchMap(val => {
                return this.filter(val)
            })
        )
    }

    filter(val3: string) {
        return this.books
            .map(response3 => response3.filter(option3 => {
                return option3.cName.toLowerCase().includes(val3) || option3.cSku.toLowerCase().includes(val3)
            }));
    }

    printTicket() {

    }

    displayBook(book) {
        return book ? book.cName : undefined;
    }

    deleteItem(data) {
        this.salesBooks.splice(data, 1);
        this.dataSource.data = this.salesBooks;
        this.nTotalBooks = this.getTotalBooks();
        this.nTotal = this.getTotal();
    }

    finalizeSale() {
        let body = {
            idCustomer: null,
            cNote: "",
            books: this.salesBooks
        }
        console.log(body)

        this.salesService.saveSale(body).subscribe((res) => {
            this.toastr.success('Éxito', 'Venta registrada');
            this.clearSale();
        }, err => {
            //this.isLoading = false;
            this.toastr.error('Error', 'Occurrio un error al insertar el libro');
        });

    }

    calcTotals() {
        if (this.oBook) {
            if (this.oBook.idBook) {
                if (this.oBook.nQuantity > 0) {
                    if (this.nQuantity <= this.oBook.nQuantity) {
                        this.bEnableAddButton = true
                        this.nPrice = this.oBook.nPrice;
                        this.nSubTotal = (this.nQuantity * this.oBook.nPrice) - ((this.nDiscount * (this.nQuantity * this.oBook.nPrice)) / 100);
                    } else {
                        delete this.nQuantity
                        this.nQuantity = this.oBook.nQuantity;
                        this.toastr.warning('Solo hay disponibles ' + this.oBook.nQuantity, 'Atención');
                    }

                } else {
                    this.bEnableAddButton = false
                    this.toastr.warning('Este libro esta agotado', 'Atención');
                }
            }
        }
    }

    clearSale() {
        this.salesBooks = [];
        this.dataSource.data = this.salesBooks;
        this.nDiscount = 0;
        this.nPrice = 0;
        this.nSubTotal = 0;
        this.nQuantity = 1;
        this.nTotalBooks = 0;
        this.nTotal = 0;
    }


    outOfInventory() {
        if (this.oBook) {
            if (this.nQuantity > 0) {
                var sale = {
                    idBook: this.oBook.idBook,
                    nQuantity: this.oBook.nQuantity
                }
                this.salesService.outOfInventory(sale).subscribe((res) => {
                    this.toastr.success('Éxito', 'Salida registrada');
                    delete this.oBook;
                }, err => {
                    //this.isLoading = false;
                    this.toastr.error('Error', 'Occurrio un error al registrar salida');
                });
            } else
                this.toastr.warning('La cantidad debe ser mayor a cero', 'Atención');
        } else
            this.toastr.warning('Debe selecciona un libro primero', 'Atención');
    }


    addToSale() {
        if (this.oBook) {
            if (this.nQuantity > 0) {
                let mya = new SaleBook();
                mya.cName = this.oBook.cName;
                mya.cSku = this.oBook.cSku
                mya.idBook = this.oBook.idBook;
                mya.nCost = this.oBook.nCost;
                mya.nDiscount = this.nDiscount;
                mya.nPrice = this.oBook.nPrice;
                mya.nQuantity = this.nQuantity;
                mya.nSubtotal = this.nSubTotal;
                this.salesBooks.push(mya)
                this.dataSource.data = this.salesBooks;
                this.nDiscount = 0;
                this.nPrice = 0;
                this.nSubTotal = 0;
                this.nQuantity = 1;
                delete this.oBook;
                this.nTotalBooks = this.getTotalBooks();
                this.nTotal = this.getTotal();
                this.bEnableAddButton = false
            } else
                this.toastr.warning('La cantidad debe ser mayor a cero', 'Atención');
        } else
            this.toastr.warning('Debe selecciona un libro primero', 'Atención');
    }


    getTotal() {
        return this.salesBooks.map(t => t.nSubtotal).reduce((acc, value) => acc + value, 0);
    }

    getTotalBooks() {
        return this.salesBooks.map(t => t.nQuantity).reduce((acc, value) => acc + value, 0);
    }

    ngOnInit() {

    }
}