import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inventorysales',
  templateUrl: './inventorysales.component.html',
  styleUrls: ['./inventorysales.component.css']
})
export class InventorysalesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
